import Vue from 'vue';
import VueRouter from 'vue-router';
// @ts-ignore
import OAuthConnect from '@manifoldxyz/oauth-connect-widget';
import App from './App.vue';

Vue.use(VueRouter);
Vue.use(OAuthConnect.OAuthConnect);

Vue.config.productionTip = false;

new Vue({
  router: new VueRouter({
    routes: [],
    mode: 'history',
  }),
  render: (h) => h(App),
}).$mount('#app');
