import { SATISFY_BACKEND_URI } from '../constants';
// import { EthereumProvider } from "@manifoldxyz/manifold-sdk"

type Headers = {
  Accept: string;
  'Content-Type': string;
  Authorization?: string;
};

export class SatisfyApiSDK {
  accessToken: string | undefined;
  server: string = SATISFY_BACKEND_URI;
  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }
  clearAccessToken() {
    this.accessToken = undefined;
  }
  getHeaders() {
    const headers: Headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (this.accessToken) {
      headers['Authorization'] = `Bearer ${this.accessToken}`;
    }
    return headers;
  }
  async fetch(path: string, options: any = {}) {
    let url = this.server + path;
    if (options.query) {
      const queryString = new URLSearchParams(options.query).toString();
      url = url + '?' + queryString;
    }
    const requestOptions = {
      method: 'GET',
      headers: this.getHeaders(),
      ...options,
    };
    const response = await fetch(url, requestOptions);
    if (response.status >= 400 && response.status <= 500) {
      if (response.status === 403 || response.status === 401) {
        // @ts-ignore
        // EthereumProvider.disconnect(true)
      }
      console.error(response);
      throw new Error(`Received status '${response.status}' from server`);
    }
    return response;
  }

  async post(path: string, options: any = {}) {
    let url = this.server + path;
    if (options.query) {
      const queryString = new URLSearchParams(options.query).toString();
      url = url + '?' + queryString;
    }
    const requestOptions = {
      method: 'POST',
      headers: this.getHeaders(),
      ...options,
    };
    const response = await fetch(url, requestOptions);
    if (response.status >= 400 && response.status <= 500) {
      if (response.status === 403 || response.status === 401) {
        // @ts-ignore
        // EthereumProvider.disconnect(true);
      }
      console.error(response);
      throw new Error(`Received status '${response.status}' from server`);
    }
    return response;
  }

  async me() {
    // This will return a 404 if no existing strava link was found
    try {
      const response = await this.fetch('/strava/me');
      return response.json();
    } catch (e) {
      return null;
    }
  }

  async linkStrava() {
    const response = await this.fetch('/strava/connect');
    const { url } = await response.json();
    window.location.href = url;
  }

  async unlinkStrava() {
    const response = await this.post('/strava/disconnect');
    return response.json();
  }

  async token(tokenId: string | number) {
    const response = await this.fetch(`/token/${tokenId}`);
    return response.json();
  }
}
