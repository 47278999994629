var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-white min-h-screen grid items-center justify-center",
      attrs: { id: "app" },
    },
    [
      !_vm.walletConnected
        ? _c(
            "div",
            {
              staticStyle: {
                "max-height": "calc(100vh - 15rem)",
                "max-width": "100vw",
                "aspect-ratio": "701 / 364",
              },
            },
            [
              _c("img", {
                staticClass: "object-cover",
                attrs: {
                  src: "https://res.cloudinary.com/treasure-trove/image/upload/v1666653844/norda-001-silver_2000x.progressive_zmo27e.webp",
                },
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        !_vm.walletConnected
          ? _c("div", { staticClass: "flex flex-col items-center" }, [
              !_vm.isFetchingTokenInfo
                ? _c(
                    "div",
                    { staticClass: "flex flex-col items-center" },
                    [
                      _c("p", { attrs: { align: "center" } }, [
                        _vm._v(
                          " Connect your wallet to access your Satisfy® Norda™ 001 Silver dynamic digital twin. "
                        ),
                      ]),
                      _c("m-oauth-connect", {
                        staticClass:
                          "text-base font-medium shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-none",
                        style: {
                          display: !_vm.walletConnected ? "block" : "none",
                        },
                        attrs: {
                          "client-id":
                            "9f78dc30aa37559aa81b88783670f1ff37813181e8f38c42ee6ac41087f6dfe4",
                          "app-name": "Satisfy",
                          "show-balance": false,
                          "auto-reconnect": true,
                          "show-chain": false,
                          network: _vm.network,
                          message:
                            "Sign this message to access the SATISFY® NORDA™ portal",
                          "grant-type": "signature",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "flex flex-row justify-center gap-5" },
                    [
                      _c("Loading"),
                      _vm._v(" "),
                      _c("span", [_vm._v("Loading...")]),
                    ],
                    1
                  ),
            ])
          : _c("div", { staticClass: "flex flex-col items-center my-5 px-5" }, [
              _vm.token && _vm.token.name
                ? _c("div", { staticClass: "space-y-5" }, [
                    _c("div", { staticClass: "text-2xl font-bold" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.tokenOpenseaUrl,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.token.name))]
                      ),
                    ]),
                    _c("div", { staticClass: "relative" }, [
                      !_vm.isVideoLoaded
                        ? _c("img", {
                            attrs: {
                              src: _vm.token.image,
                              height: "500px",
                              width: "500px",
                            },
                          })
                        : _vm._e(),
                      _vm.token.animation_url &&
                      _vm.token.animation_url.endsWith(".mp4")
                        ? _c("video", {
                            class: { invisible: !_vm.isVideoLoaded },
                            attrs: {
                              autoplay: "",
                              loop: "",
                              controls: "",
                              src: _vm.token.animation_url,
                              height: "500px",
                              width: "500px",
                            },
                            on: {
                              canplaythrough: function ($event) {
                                _vm.isVideoLoaded = true
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-2 gap-5 my-5" },
                      _vm._l(_vm.token.attributes || [], function (attribute) {
                        return _c(
                          "dl",
                          {
                            key: attribute.trait_type,
                            staticClass:
                              "flex flex-col items-center justify-center py-2 text-center rounded-lg px-1 bg-black bg-opacity-5",
                          },
                          [
                            _c("dt", { staticClass: "text-sm" }, [
                              _vm._v(_vm._s(attribute.trait_type)),
                            ]),
                            _c(
                              "dd",
                              {
                                staticClass:
                                  "!overflow-y-hidden font-display font-bold text-default text-md",
                              },
                              [_vm._v(" " + _vm._s(attribute.value) + " ")]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.isLoadingAthlete
                      ? _c("div", [_vm._v("Loading athlete info...")])
                      : !_vm.athlete
                      ? _c("div", { attrs: { align: "center" } }, [
                          _vm._v(" Login with Strava below "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "bg-black text-white text-sm p-3 mt-3",
                              on: { click: _vm.linkStrava },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isLinkingStrava
                                      ? "Linking..."
                                      : "Link Strava"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _c(
                          "div",
                          { staticClass: "flex items-center justify-between" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex items-center mt-3" },
                              [
                                _c("div", [
                                  _c("img", {
                                    staticClass:
                                      "inline-block h-9 w-9 rounded-full",
                                    attrs: {
                                      src: _vm.athlete.profile,
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "ml-3" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "self-start text-sm font-medium text-gray-700 group-hover:text-gray-900",
                                    },
                                    [
                                      _vm._v(
                                        " Welcome, " +
                                          _vm._s(_vm.athlete.firstname) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "mt-3 bg-black text-white text-sm p-3",
                                on: { click: _vm.unlinkStrava },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isUnlinkingStrava
                                        ? "Unlinking..."
                                        : "Unlink Strava Account"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                  ])
                : _c("p", [_vm._v("No tokens found")]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }