var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex justify-center items-center" }, [
    _c("div", { staticClass: "hidden h-4 w-4 h-6 w-6 h-8 w-8 h-10 w-10" }),
    _c(
      "svg",
      { class: "animate-spin h-5 w-5", attrs: { viewBox: "0 0 24 24" } },
      [
        _c("path", {
          staticClass: "opacity-75",
          attrs: {
            fill: "currentColor",
            d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }